import React from "react"

export default () => (
	<>
		<section className="banner_area" style={{minHeight:"200px"}}>
			<div className="banner_inner d-flex align-items-center" style={{minHeight:"200px"}}>
				<div className="container">
					<div className="banner_content text-center">
						<h2>Page not found</h2>
					</div>
				</div>
			</div>
		</section>
		<div style={{backgroundColor:"white", margin:"20px", padding:"5px", textAlign:"center"}}>
			<h4> </h4>This page does not exist.
		</div>
	</>
);