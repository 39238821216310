import React from "react"

import Navbar from './website_navbar'
import { Helmet } from "react-helmet"
import s from "../../images/s.svg"


export default ({children}) => (
	<>
		<Helmet>
			<meta charSet="utf-8" />
			<title>Scratchwork</title>
			<link rel="icon" href={s} />
		</Helmet>
		<Navbar />
		{children}
	</>
)