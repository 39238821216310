import React, { useState, useEffect } from "react"

import { useLocation } from "@reach/router"

import logo from "./static/img/logo.svg"
import { getSession } from "../../services/auth"
import { Link } from "../../services/routes" // "gatsby"
import routes from "../../services/routes"


import css from "./website_navbar.module.css"


export default () => {
	const [sticky, setSticky] = useState(false);

	const session = getSession();
	const location = useLocation();
	const loggedIn = session.user!==null && location.pathname!==routes.logout;

	const scrollListener = () => {
		setSticky(window.scrollY > 150);
	};

	useEffect(() => {
		window.addEventListener("scroll", scrollListener);
		return () => {
			window.removeEventListener("scroll", scrollListener);
		}
	}, []);

	return (
	<header className={sticky ? "header_area navbar_fixed" : "header_area"}>
		<div className="main_menu">
			<nav className="navbar navbar-expand-lg navbar-light">
				<div className="container box_1620">
					<Link className="navbar-brand logo_h" to={routes.index}>
						<img style={{width:"200px"}} src={logo} alt={"logo"} />
					</Link>
					<button
						className="navbar-toggler"
						type="button"
						data-toggle="collapse"
						data-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
						<span className="icon-bar"></span>
					</button>
					<div className={`collapse navbar-collapse offset ${css.navbarCollapse}`} id="navbarSupportedContent">
						<ul className="nav navbar-nav menu_nav justify-content-center">
							<li className="nav-item">
								<Link className="nav-link" to={routes.index}>
									Home
								</Link>
							</li>
							{/*<li className="nav-item">
								<Link className="nav-link" to={routes.educators}>
									For Educators
								</Link>
							</li>*/}
							<li className="nav-item">
								<Link className="nav-link" to={routes.faq}>
									FAQ
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={routes.pricing}>
									Pricing
								</Link>
							</li>
							<li className="nav-item">
								<Link className="nav-link" to={routes.contact}>
									Contact
								</Link>
							</li>
							{ !loggedIn &&
								<li className="nav-item">
									<Link className="nav-link" to={routes.login}>
										Log In
									</Link>
								</li>
							}
							{ !loggedIn &&
								<li className="nav-item">
									<Link className="nav-link" to={routes.signup}>
										Sign Up
									</Link>
								</li>
							}
							{ loggedIn &&
								<li className="nav-item">
									<Link className="nav-link" to={routes.account.boards}>
										My Boards
									</Link>
								</li>
							}
							{ loggedIn &&
								<li className="nav-item">
									<Link className="nav-link" to={routes.logout}>
										Logout
									</Link>
								</li>
							}
						</ul>
						<ul className="nav navbar-nav navbar-right">
							{ !loggedIn &&
								<li className="nav-item">
									<Link className="tickets_btn" to={routes.demo}>
										Try Demo
									</Link>
								</li>
							}
							{ loggedIn &&
								<li className="nav-item">
									<Link className="tickets_btn" to={routes.new_board}>
										New Board
									</Link>
								</li>
							}
						</ul>
					</div>
				</div>
			</nav>
		</div>
	</header>
	);
}
